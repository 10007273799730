import React, { FunctionComponent } from "react"
import Navbar from "./navbar"
import Img from "gatsby-image"
import { Link, GatsbyLinkProps } from "gatsby"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const query = graphql`
    query GetLogo {
        image: file(relativePath: { eq: "logo.png" }) {
            childImageSharp {
                fixed(width: 100) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
    }
`

const Name: FunctionComponent = () => {
    // return <div className="text-2xl">Test Name</div>
    const data = useStaticQuery(query)
    const imageData = data.image.childImageSharp.fixed
    return (
        <div className="flex">
            <div className="lg:hidden">
                <Img
                    className="mr-4  rounded"
                    fixed={imageData}
                    alt="hsds image"
                />
            </div>
            <div className="hidden lg:flex items-center">
                <Img
                    className="mr-4 rounded"
                    fixed={imageData}
                    alt="hsds image"
                />
                <div>High Speed Design Services</div>
            </div>
        </div>
    )
}

const sitePages = [
    { name: "Home", path: "/" },
    { name: "About Us", path: "/about-us/" },
    { name: "Services", path: "/services/" },
    { name: "Learning Center", path: "/learning-center/" },
    { name: "Contact Us", path: "/contact-us/" },
    { name: "Blog", path: "https://ghost.hsdpcb.com", href: true },
]

const isActive = ({ isCurrent }: { isCurrent: boolean }) => {
    let classes = "text-base mr-2 block md:inline px-2 py-1 hover:text-white "
    classes += isCurrent ? "text-white" : "text-gray-400"
    return { className: classes }
}

const Links: FunctionComponent = () => {
    return (
        <div>
            {sitePages.map((page) =>
                page.href ? (
                    <a
                        className="text-base mr-2 block md:inline px-2 py-1 hover:text-white text-gray-400"
                        href={page.path}
                        target="_blank"
                        key={page.name}
                    >
                        {page.name}
                    </a>
                ) : (
                    <Link getProps={isActive} to={page.path} key={page.name}>
                        {page.name}
                    </Link>
                )
            )}
        </div>
    )
}

type LayoutProps = {
    title?: string
    children: React.ReactNode
}

const Layout: FunctionComponent<LayoutProps> = ({ title, children }) => {
    return (
        <div className="relative font-body min-h-screen pb-16">
            <Helmet
                title={
                    title
                        ? `${title} | High Speed Design Services`
                        : "High Speed Design Services"
                }
            />
            <Navbar name={<Name />} links={<Links />} />
            <div className="py-4">{children}</div>
            <footer className="absolute bottom-0 left-0 right-0 text-center p-3 bg-gray-300">
                <div className="mx-auto container">
                    Copyright © {new Date().getFullYear()} High Speed Design
                    Services. All rights reserved.
                </div>
            </footer>
        </div>
    )
}

export default Layout
