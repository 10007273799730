import React, { FunctionComponent, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons"

type NavbarProps = {
    name: React.ReactNode
    links: React.ReactNode
}

const Navbar: FunctionComponent<NavbarProps> = ({
    name,
    links,
}: NavbarProps) => {
    let [isCollapsed, setIsCollapsed] = useState(true)

    return (
        <nav className="bg-black p-3">
            <div className="mx-auto container md:px-4">
                <div className="relative flex items-center justify-between h-16">
                    <div className="absolute inset-y-0 left-0 flex items-center md:hidden">
                        <button
                            onClick={() => setIsCollapsed(!isCollapsed)}
                            className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                            aria-expanded="false"
                        >
                            <FontAwesomeIcon
                                icon={isCollapsed ? faBars : faTimes}
                            />
                        </button>
                    </div>
                    <div className="flex-1 flex items-center justify-center md:items-stretch md:justify-start">
                        <div className="flex-shrink-0 flex items-center">
                            <span className="text-white font-semibold text-xl tracking-tight">
                                {name}
                            </span>
                        </div>
                        <div className="hidden md:flex items-center md:ml-4">
                            <div className="flex items-center">{links}</div>
                        </div>
                    </div>
                </div>

                <div className="absolute inset-y-0 right-0 flex items-center pr-2 md:static md:inset-auto md:ml-6 md:pr-0">
                    <slot name="right" />
                </div>
            </div>

            <div
                className={`${
                    isCollapsed ? "hidden" : ""
                } md:hidden mx-auto container md:px-4`}
            >
                <div className="pt-2 pb-3 space-y-1">{links}</div>
            </div>
        </nav>
    )
}

export default Navbar
